module.exports = [{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2048},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/opt/build/repo/packages/theme/src/cms/cms.js"},
    },{
      plugin: require('../../theme/gatsby-browser.js'),
      options: {"plugins":[],"shopName":"alex-boffeys-store","accessToken":"cac8fcda2b4279fb6e6659b4052da214"},
    }]
