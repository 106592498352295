import React from 'react';
import Client from 'shopify-buy';

import {
  Checkout,
  Collection,
  Product,
  ShopifyBuyClient,
  Shop,
  ShopifyServiceAction,
  addVariantToCartParams,
  updateLineItemInCartParams,
  removeLineItemInCartParams,
} from './types';

const client = Client.buildClient({
  domain: `${process.env.GATSBY_SHOPIFY_STORE_NAME}.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_ACCESS_TOKEN,
});

export interface ShopifyServiceStateContext {
  client: ShopifyBuyClient;
  checkout?: Checkout;
  products: Product[];
  collections: Collection[];
  shop?: Shop; // for now
  loading: boolean;
  error?: Error;
  // TODO interface context
  isCartOpen: boolean;
  // TODO
  isCustomerAuthOpen: boolean;
  isNewCustomer: boolean;
}

interface ShopifyServiceDispatchContext {
  addVariantToCart: (payload: addVariantToCartParams) => void;
  removeLineItemInCart: (payload: removeLineItemInCartParams) => void;
  updateLineItemInCart: (payload: updateLineItemInCartParams) => void;
  dispatch: React.Dispatch<ShopifyServiceAction>;
}

export const initialStateContext: ShopifyServiceStateContext = {
  client,
  products: [],
  collections: [],
  shop: undefined,
  loading: true,
  error: undefined,
  checkout: undefined,
  isCartOpen: false,
  isCustomerAuthOpen: false,
  isNewCustomer: false,
};

export const initialDispatchContext: ShopifyServiceDispatchContext = {
  addVariantToCart: () => null,
  removeLineItemInCart: () => null,
  updateLineItemInCart: () => null,
  dispatch: () => null,
};

export const ShopifyServiceStateContext = React.createContext<ShopifyServiceStateContext>(
  initialStateContext
);
export const ShopifyServiceDispatchContext = React.createContext<ShopifyServiceDispatchContext>(
  initialDispatchContext
);

ShopifyServiceStateContext.displayName = 'ShopifyServiceStateContext';
ShopifyServiceDispatchContext.displayName = 'ShopifyServiceDispatchContext';

export const reducer = (
  state: ShopifyServiceStateContext,
  action: ShopifyServiceAction
): ShopifyServiceStateContext => {
  switch (action.type) {
    case 'updateCheckout': {
      // Check for an existing cart.
      const existingCheckoutID =
        typeof window !== 'undefined'
          ? localStorage.getItem(`${process.env.LOCAL_STORAGE_KEY}.shopifyCheckoutId`)
          : null;

      if (state.checkout && state.checkout.id !== existingCheckoutID) {
        localStorage.setItem(
          `${process.env.LOCAL_STORAGE_KEY}.shopifyCheckoutId`,
          state.checkout.id.toString()
        );
      }

      return { ...state, checkout: action.payload.checkout };
    }

    case 'setError': {
      return { ...state, error: action.error };
    }

    case 'setShop': {
      return { ...state, shop: action.shop };
    }

    case 'setProducts': {
      return { ...state, products: action.products };
    }

    case 'setCollections': {
      return { ...state, collections: action.collections };
    }

    case 'openCart': {
      return { ...state, isCartOpen: true };
    }

    case 'closeCart': {
      return { ...state, isCartOpen: false };
    }

    case 'toggleCart': {
      return { ...state, isCartOpen: !state.isCartOpen };
    }

    case 'setLoading': {
      return { ...state, loading: action.loading };
    }

    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};
