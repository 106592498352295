// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---theme-src-pages-404-js": () => import("./../../theme/src/pages/404.js" /* webpackChunkName: "component---theme-src-pages-404-js" */),
  "component---theme-src-pages-blog-index-tsx": () => import("./../../theme/src/pages/blog/index.tsx" /* webpackChunkName: "component---theme-src-pages-blog-index-tsx" */),
  "component---theme-src-pages-contact-examples-js": () => import("./../../theme/src/pages/contact/examples.js" /* webpackChunkName: "component---theme-src-pages-contact-examples-js" */),
  "component---theme-src-pages-contact-file-upload-js": () => import("./../../theme/src/pages/contact/file-upload.js" /* webpackChunkName: "component---theme-src-pages-contact-file-upload-js" */),
  "component---theme-src-pages-contact-index-tsx": () => import("./../../theme/src/pages/contact/index.tsx" /* webpackChunkName: "component---theme-src-pages-contact-index-tsx" */),
  "component---theme-src-pages-contact-thanks-js": () => import("./../../theme/src/pages/contact/thanks.js" /* webpackChunkName: "component---theme-src-pages-contact-thanks-js" */),
  "component---theme-src-pages-tags-index-js": () => import("./../../theme/src/pages/tags/index.js" /* webpackChunkName: "component---theme-src-pages-tags-index-js" */),
  "component---theme-src-templates-about-page-tsx": () => import("./../../theme/src/templates/about-page.tsx" /* webpackChunkName: "component---theme-src-templates-about-page-tsx" */),
  "component---theme-src-templates-blog-post-tsx": () => import("./../../theme/src/templates/blog-post.tsx" /* webpackChunkName: "component---theme-src-templates-blog-post-tsx" */),
  "component---theme-src-templates-index-page-tsx": () => import("./../../theme/src/templates/index-page.tsx" /* webpackChunkName: "component---theme-src-templates-index-page-tsx" */),
  "component---theme-src-templates-product-listing-tsx": () => import("./../../theme/src/templates/ProductListing.tsx" /* webpackChunkName: "component---theme-src-templates-product-listing-tsx" */),
  "component---theme-src-templates-product-page-tsx": () => import("./../../theme/src/templates/ProductPage.tsx" /* webpackChunkName: "component---theme-src-templates-product-page-tsx" */),
  "component---theme-src-templates-tags-tsx": () => import("./../../theme/src/templates/tags.tsx" /* webpackChunkName: "component---theme-src-templates-tags-tsx" */)
}

