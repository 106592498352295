import React, { useReducer, useEffect } from 'react';
import {
  initialStateContext,
  ShopifyServiceDispatchContext,
  ShopifyServiceStateContext,
  reducer,
} from './context';
import {
  addVariantToCartParams,
  removeLineItemInCartParams,
  updateLineItemInCartParams,
} from './types';

export const ShopifyServiceProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialStateContext);

  // Initialise data
  useEffect(() => {
    const asyncFn = async () => {
      dispatch({ type: 'setLoading', loading: true });
      try {
        const [products, collections, shopInfo, shopPolicies] = await Promise.all([
          state.client.product.fetchAll(),
          state.client.collection.fetchAllWithProducts(),
          state.client.shop.fetchInfo(),
          state.client.shop.fetchPolicies(),
        ]);
        dispatch({ type: 'setProducts', products });
        dispatch({ type: 'setCollections', collections });
        dispatch({ type: 'setShop', shop: { ...shopInfo, ...shopPolicies } });
      } catch (error) {
        dispatch({ type: 'setError', error });
      }
      dispatch({ type: 'setLoading', loading: false });
    };
    asyncFn();
  }, [state.client.collection, state.client.shop, state.client.product]);

  // Initialise checkout
  useEffect(() => {
    const asyncFn = async () => {
      if (!state.checkout) {
        // Check for an existing cart.
        const existingCheckoutID =
          typeof window !== 'undefined'
            ? localStorage.getItem(`${process.env.LOCAL_STORAGE_KEY}.shopifyCheckoutId`)
            : null;

        if (existingCheckoutID) {
          try {
            const checkout = await state.client.checkout.fetch(existingCheckoutID);
            // Make sure this cart hasn’t already been purchased.
            // TODO Fix type
            if (!checkout.completedAt) {
              dispatch({
                type: 'updateCheckout',
                payload: { checkout },
              });
              return;
            }
          } catch (error) {
            dispatch({ type: 'setError', error });
            localStorage.setItem(`${process.env.LOCAL_STORAGE_KEY}.shopifyCheckoutId`, null);
          }
        }

        const checkout = await state.client.checkout.create();
        dispatch({
          type: 'updateCheckout',
          payload: { checkout },
        });
        localStorage.setItem(
          `${process.env.LOCAL_STORAGE_KEY}.shopifyCheckoutId`,
          checkout.id.toString()
        );
      }
    };

    asyncFn();
  }, [state.checkout, state.client.checkout]);

  const addVariantToCart = async ({ quantity, variantId }: addVariantToCartParams) => {
    dispatch({ type: 'setLoading', loading: true });
    const checkout = await state.client.checkout.addLineItems(state.checkout.id, [
      { variantId: variantId.toString(), quantity: parseInt(quantity, 10) },
    ]);
    dispatch({ type: 'setLoading', loading: false });
    dispatch({
      type: 'updateCheckout',
      payload: { checkout },
    });
  };

  const removeLineItemInCart = async ({ lineItemId }: removeLineItemInCartParams) => {
    dispatch({ type: 'setLoading', loading: true });
    const checkout = await state.client.checkout.removeLineItems(state.checkout.id, [lineItemId]);
    dispatch({ type: 'setLoading', loading: false });
    dispatch({
      type: 'updateCheckout',
      payload: { checkout },
    });
  };

  const updateLineItemInCart = async ({ quantity, lineItemId }: updateLineItemInCartParams) => {
    dispatch({ type: 'setLoading', loading: true });
    const checkout = await state.client.checkout.updateLineItems(state.checkout.id, [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ]);
    dispatch({ type: 'setLoading', loading: false });
    dispatch({
      type: 'updateCheckout',
      payload: { checkout },
    });
  };

  // TODO user association

  return (
    <ShopifyServiceStateContext.Provider value={state}>
      <ShopifyServiceDispatchContext.Provider
        value={{
          addVariantToCart,
          removeLineItemInCart,
          updateLineItemInCart,
          dispatch,
        }}
      >
        {children}
      </ShopifyServiceDispatchContext.Provider>
    </ShopifyServiceStateContext.Provider>
  );
};
